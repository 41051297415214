import {navigate } from "gatsby"
import React, {useState, useContext, useEffect} from "react"
import { FirebaseContext } from '@components/Firebase'
import { Button } from '@common'
import FlexWrapper from '@common/layout/FlexWrapper'
import Form from '@form/Form'
import Input from '@form/Input'
import LineText from '@form/LineText'
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import FlashMessage from '@notification/FlashMessage'
import google from "@images/logos/google-icon-80x80.png"
import facebook from "@images/logos/facebook-icon-blue-80x80.png"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Seo from '@components/Seo'
import Header from "@menus/header"

const  SocialLoginButton =  styled(Button)`
  width:48%;
  background: #ececec;
  color:${props => props.white ? "white": "#606060"};
  border-width: 2px;
  border-color: #ececec;
  &:hover{
    color:white;
    background:#c7c7c7;
    border-color: #c7c7c7;
  }
`

const SocialLoginWrapper = styled.div`
  display:flex;
  justify-content:space-between;
`
const ButtonIcon = styled.img`
  height:1rem;
`
const ButtonContentWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content: center;
`

const ButtonText = styled.p`
  margin:0px;
  padding-left:20px;
  @media ${props => props.theme.phonePort} {
    padding-left:5px;
  }
`
const Login = (props) => {
  const [formValues, setFormValues] = useState({email: '', password: ''});
  const [flashMessage, setFlashMessage] = useState('');
  const {firebase,user} = useContext(FirebaseContext);
  let isMounted = true;
  const { t } = useTranslation()
  const lang = props.pageContext.lang;
  
  async function handleSubmit(e){
    e.preventDefault(); // prevent redirect after submit
    firebase.login({email: formValues.email, password: formValues.password, lang}).catch(error => {
      if(isMounted){
        console.log(error);
        setFlashMessage({"message": error.message,"type":"error"});
      } 
    }).then(()=>{
      typeof window !== "undefined" && window.gtag && window.gtag('event', 'login', { method : 'Email' });
    })
  }

  function handleSocialLogin(provider){
    firebase && firebase.socialLogin(provider,"login",lang);
  }

  function handleInputChange(e){
    e.persist(); // dont know why
    setFlashMessage(''); // clean error message when inputs change
    setFormValues(currentValues =>({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }   

  function redirectToRegister(){
    navigate(t('urls.register'))
  }

  return (
    <FlexWrapper around >
      <Header location={props.location}/>  
      <Seo lang={props.pageContext.lang} originalPath={props.pageContext.originalPath} title="Login"/>
      <Form onSubmit={handleSubmit} marginTopBot>
      <MainHeading center>{t('login.sectionTitle')}</MainHeading>
        {/* <FormLabel>Email</FormLabel> */}
        <Input insetShadow required value ={formValues.email} name="email" placeholder={t('login.emailPlaceholder')} onChange={handleInputChange} type="email" autocomplete="on"/>
        {/* <FormLabel>Password</FormLabel> */}
        <Input insetShadow required value ={formValues.password} name="password" placeholder={t('login.pwPlaceholder')} onChange={handleInputChange} type ="password" autocomplete="on"/>
        {flashMessage && <FlashMessage message={flashMessage}/>}
        <Button type="submit" block marginTop>
        {t('login.submitButton')}
        </Button>
        <LineText>{t('login.lineText')}</LineText>
        <SocialLoginWrapper>
        <SocialLoginButton type="button" inverted border  onClick={() => handleSocialLogin("Google")}>
          <ButtonContentWrapper>
          <ButtonIcon src={google}/>
          <ButtonText>Google</ButtonText>
          </ButtonContentWrapper>
        </SocialLoginButton>
        <SocialLoginButton type="button" inverted border   onClick={() => navigate(t('urls.facebook-login'))}>
          <ButtonContentWrapper>
          <ButtonIcon src={facebook}/>
          <ButtonText>Facebook</ButtonText>
          </ButtonContentWrapper>
        </SocialLoginButton>
        </SocialLoginWrapper>
        <Text center>{t('login.noAccount')} <StyledLink to={t('urls.reset-password')}>{t('login.resetPw')}</StyledLink></Text>
        <Button  block inverted border onClick={redirectToRegister}>
        {t('login.registerButton')}
        </Button>
      </Form>
    </FlexWrapper>
  );
}

export default Login